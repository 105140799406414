<template>
  <v-container fluid>
    <v-card outlined>
      <v-expansion-panels flat :value="myPanel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header
            >{{ $t("searchPanel") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="pa-1" cols="12" md="3" sm="6">
                <v-combobox
                  v-model="filterItem.patientName"
                  :items="patients"
                  clearable
                  :label="$t('patients.patientName')"
                  item-text="patientDisplayName"
                  item-value="patientDisplayName"
                  :return-object="false"
                  hide-details
                  outlined
                  dense
                  @change="refreshTable"
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" md="3" sm="6" class="pa-1">
                <v-autocomplete
                  v-model="filterItem.patientCategory"
                  :items="categories"
                  clearable
                  :label="$t('patients.patientCategory')"
                  item-text="name"
                  item-value="guid"
                  :return-object="false"
                  hide-details
                  outlined
                  dense
                  multiple
                >
                </v-autocomplete>
              </v-col>

              <v-col class="pa-1" cols="12" md="2" sm="3">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  clearable
                  v-model="filterItem.barcode"
                  :label="$t('tests.barcode')"
                >
                </v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" md="2" sm="3">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  clearable
                  v-model="filterItem.billCode"
                  :label="$t('bills.billCode')"
                >
                </v-text-field>
              </v-col>

              <v-col class="pa-1" cols="12" md="1" sm="3">
                <v-dialog
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterItem.fromDate"
                      hide-details
                      outlined
                      dense
                      :label="$t('fromDate')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterItem.fromDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="pa-1" cols="12" md="1" sm="3">
                <v-dialog
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      outlined
                      dense
                      v-model="filterItem.toDate"
                      :label="$t('toDate')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterItem.toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" md="4" class="pa-1 pt-0">
                <v-radio-group
                  hide-details
                  v-model="filterItem.isDone"
                  column
                  class="pa-2 px-4 mt-1 v-sheet--outlined"
                  style="border-radius: 5px"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="4"
                      sm="4"
                      v-for="(item, i) in isDoneStatus"
                      :key="i"
                      style="display: contents"
                    >
                      <v-radio
                        dense
                        hide-details
                        :label="item.text"
                        :value="item.value"
                      ></v-radio
                    ></v-col>
                  </v-row>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="4" sm="6" class="pa-1 pt-0">
                <v-radio-group
                  hide-details
                  v-model="filterItem.particularType"
                  column
                  class="py-2 px-4 mt-1 v-sheet--outlined"
                  style="border-radius: 5px"
                  @change="particularTypeChange"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      v-for="(item, i) in particularTypes"
                      :key="i"
                      style="display: contents"
                    >
                      <v-radio
                        dense
                        hide-details
                        :label="item.text"
                        :value="item.value"
                      ></v-radio
                    ></v-col>
                  </v-row>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="4" sm="6" class="pa-1 pt-0">
                <v-radio-group
                  hide-details
                  v-model="filterItem.testParticularGuid"
                  column
                  class="pa-2 px-4 mt-1 v-sheet--outlined"
                  style="border-radius: 5px"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      v-for="(item, i) in userParticular"
                      :key="i"
                    >
                      <v-radio
                        dense
                        hide-details
                        :label="
                          item.particularName == 'all'
                            ? $t(item.particularName)
                            : item.particularName
                        "
                        :value="item.testParticularGuid"
                      ></v-radio
                    ></v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-1 my-1" cols="12">
                <v-btn
                  block
                  elevation="0"
                  color="primary"
                  @click="refreshTable"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <!-- <patient-filter
      :panel="true"
      :patients="patients"
      @filterChange="filterChange"
    ></patient-filter> -->

    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <v-card outlined class="pa-5">
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <!--<v-spacer></v-spacer>
         <v-btn
          v-if="$store.getters.isInRole(72)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn> -->
          </v-toolbar>

          <!-- <data-table-component
            :headers="headers"
            :items="testRequest"
            :search="search"
            :loading="loading"
            @rowClick="dtRowClick"
          >
          </data-table-component> -->

          <v-data-table
            :items-per-page="5"
            :headers="headers"
            :items="testRequest"
            :search="search"
            :loading="loading"
            :single-select="true"
            v-model="selected"
            item-key="guid"
            @click:row="dtRowClick"
            height="400"
            hide-default-footer
            :page.sync="page"
          >
            <template v-slot:[`item.dateReg`]="{ item }">
              {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
            </template>
            <template v-slot:[`item.approvedDate`]="{ item }">
              {{ item.approvedDate | moment("YYYY/MM/DD hh:mm A") }}
            </template>
            <template v-slot:[`item.approved`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-switch
                    v-if="$store.getters.isInRole(94)"
                    v-model="item.approved"
                    v-on="on"
                    v-bind="attrs"
                    @click="approveItem(item)"
                  />
                  <v-checkbox v-else disabled v-model="item.approved" />
                </template>
                <span v-if="item.approved">
                  {{ $t("tests.cancelApproved") }}
                </span>
                <span v-else>
                  {{ $t("tests.approve") }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-row class="mt-2">
            <v-col align-self="end">
              <v-pagination
                class="mb-2"
                v-model="page"
                :length="pageLength ? pageLength : 0"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined class="pa-5">
          <!-- <v-toolbar flat> -->
          <v-row no-gutters>
            <v-col>
              <span class="mx-2">
                {{ $t("tests.sampleType") + " : " }}
                <v-chip
                  label
                  outlined
                  v-if="selectedItem.sampleType"
                  v-text="selectedItem.sampleType"
                />
              </span>
            </v-col>
            <v-col cols="auto">
              <span class="mx-2">
                {{ $t("tests.approveState") + " : " }}

                <v-chip label class="pt-1">
                  {{
                    selectedItem.approved
                      ? $t("tests.approved")
                      : $t("tests.notApproved")
                  }}
                  <v-avatar right>
                    <v-icon
                      :color="
                        selectedItem.approved ? 'success' : 'red darken-2'
                      "
                    >
                      {{
                        selectedItem.approved
                          ? "mdi-checkbox-marked-circle"
                          : "mdi-close-circle"
                      }}
                    </v-icon>
                  </v-avatar>
                </v-chip>
              </span>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col>
              <span class="mx-1">
                {{ $t("tests.barcode") + " : " }}
                <v-chip
                  label
                  outlined
                  v-if="selectedItem.barcode"
                  v-text="selectedItem.barcode"
                />
              </span>
            </v-col>
            <!-- <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-on="on" v-bind="attrs">
                    <span class="mx-1">
                      {{ $t("print") }}
                    </span>
                    <v-icon>
                      mdi-printer
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("print") }}
                </span>
              </v-tooltip>
            </v-col> -->
          </v-row>
          <!-- </v-toolbar> -->
          <v-data-table
            height="435"
            :items-per-page="-1"
            hide-default-footer
            :headers="headersDetail"
            :items="selectedItem.testHeaders"
          >
            <!-- <template v-slot:[`item.useTemplate`]="{ item }">
              <v-switch readonly v-model="item.useTemplate" />
            </template> -->
            <template v-slot:[`item.isDone`]="{ item }">
              <v-switch
                v-if="$store.getters.isInRole(96)"
                v-model="item.isDone"
                @click="doneItem(item)"
              />

              <v-checkbox v-else disabled v-model="item.isDone" />
            </template>
            <template v-slot:[`item.isDoneDate`]="{ item }">
              {{ item.isDoneDate | moment("YYYY/MM/DD hh:mm A") }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.getters.isInRole(97)"
                    v-on="on"
                    icon
                    v-bind="attrs"
                    :to="'/testResult/' + item.guid"
                    target="_blank"
                  >
                    <v-icon> mdi-file-document-edit-outline </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("tests.testResults") }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog
      :openDialog="dialogApprove"
      :onClicked="approveItemConfirm"
      :onClose="closeApprove"
      :dialogTitle="$t('tests.approve')"
    />

    <confirm-dialog
      :openDialog="dialogDone"
      :onClicked="doneItemConfirm"
      :onClose="closeDone"
      :dialogSubtitle="
        editedItem.isDone ? $t('tests.doDone') : $t('tests.removeDone')
      "
      :dialogTitle="$t('tests.changeDone')"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      loading: true,
      valid: true,
      dialogApprove: false,
      dialogDone: false,
      editedIndex: -1,
      search: "",
      patients: [],
      testRequest: [],
      editedItem: {},
      defaultItem: {},
      selectedItem: {},
      selected: [],
      userParticular: [],
      categories: [],
      resetValidation: 0,
      page: 1,
      myPanel: [0],
      menu1: false,
      menu2: false,
      filterItem: {
        patientName: "",
        fromDate: this.$moment().format("YYYY-MM-DD"),
        toDate: this.$moment().format("YYYY-MM-DD"),
        testParticularGuid: "00000000-0000-0000-0000-000000000000",
        barcode: "",
        billCode: "",
        isDone: 0,
        particularType: null,
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  computed: {
    particularTypes() {
      var list = [];
      if (this.$store.getters.isInRole(106))
        list.push({
          text: this.$t("tests.particularTypes.Pathology"),
          value: 0,
        });
      if (this.$store.getters.isInRole(107))
        list.push({
          text: this.$t("tests.particularTypes.Radiology"),
          value: 1,
        });
      if (this.$store.getters.isInRole(108))
        list.push({
          text: this.$t("tests.particularTypes.Histopathology"),
          value: 2,
        });
      if (this.$store.getters.isInRole(109))
        list.push({
          text: this.$t("tests.particularTypes.Audiology"),
          value: 3,
        });

      return list;
    },
    pageLength() {
      try {
        var div = this.testRequest.length / 5;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      } catch (error) {
        return 1;
      }
    },
    headersDetail() {
      var list = [
        { text: this.$t("tests.seq"), value: "seq" },
        { text: this.$t("tests.sampleCode"), value: "sampleCode" },
        { text: this.$t("tests.shortCode"), value: "shortCode" },

        { text: this.$t("tests.isDone"), value: "isDone", type: "switch" },
        { text: this.$t("tests.isDoneDate"), value: "isDoneDate" },
        { text: this.$t("tests.isDoneUserName"), value: "isDoneUserName" },
        { text: "", value: "actions" },
      ];
      return list;
    },
    isDoneStatus() {
      var list = [
        { text: this.$t("tests.notDone"), value: 0 },
        { text: this.$t("tests.done"), value: 1 },
        { text: this.$t("vouchers.both"), value: 2 },
      ];
      return list;
    },

    headers() {
      var list = [
        { text: this.$t("tests.seq"), value: "seq" },
        { text: this.$t("patients.patientName"), value: "patientName" },
        {
          text: this.$t("date"),
          value: "dateReg",
          type: "date",
          format: "YYYY/MM/DD hh:ss a",
        },
        {
          text: this.$t("tests.approveState"),
          value: "approved",
          type: "switch",
        },
        { text: this.$t("tests.approvedDate"), value: "approvedDate" },
        { text: this.$t("tests.approvedUserName"), value: "approvedUserName" },
      ];
      return list;
    },
  },
  created() {
    this.loading = true;

    if (this.particularTypes.length > 0) {
      this.filterItem.particularType = this.particularTypes[0].value;
      this.refreshTable();
      this.getUserParticular();
    }

    this.getPatients();
    this.getPatientCategories();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    selected(val) {
      if (val[0]) {
        this.selectedItem = val[0];
      } else {
        this.selectedItem = {};
      }
    },
  },

  methods: {
    approveItem(item) {
      this.editedIndex = this.testRequest.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },
    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.refreshTable();
    },

    doneItem(item) {
      if (this.selectedItem.approved) {
        this.editedIndex = this.selectedItem.testHeaders.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDone = true;
      } else {
        this.$toast.error(this.$t("error.ApproveFirst"));
        this.refreshTable();
      }
    },
    closeDone() {
      this.dialogDone = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.refreshTable();
    },

    refreshTable() {
      this.$axios
        .get("TestRequest?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.testRequest = response.data.data;

          var guid = this.selectedItem.guid;
          var selected = this.testRequest.filter((f) => f.guid == guid);
          if (selected.length > 0) {
            this.selected = selected;
          } else {
            this.selected = [];
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPatients() {
      this.$axios
        .get("Patient")
        .then((response) => {
          this.patients = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },
    getPatientCategories() {
      this.$axios
        .get("Category")
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserParticular() {
      this.$axios
        .get(
          "TestParticularUser/GetUserParticular?type=" +
            this.filterItem.particularType
        )
        .then((response) => {
          this.userParticular = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },

    filterChange(filterItem) {
      this.filterItem = filterItem;
      this.refreshTable();
    },

    dtRowClick(x) {
      this.selected = [x];
    },

    approveItemConfirm() {
      this.$axios
        .get("TestRequest/Approve?guid=" + this.editedItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
            this.refreshTable();
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });

      this.closeApprove();
    },
    doneItemConfirm() {
      this.$axios
        .get("TestHeader/Done?guid=" + this.editedItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
            this.refreshTable();
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });

      this.closeDone();
    },

    particularTypeChange() {
      this.filterItem.testParticularGuid =
        "00000000-0000-0000-0000-000000000000";
      this.getUserParticular();
      this.refreshTable();
    },
  },
};
</script>

<style></style>
